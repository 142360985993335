<template>
  <div>
    <!-- 多选-->
    <div v-if="items.source.data.length > 0 || (items.source.from != '' && items.source.type == 'dic')">
      <van-field :name="items.name" v-model="checkeds[0]" :label="name=='edits'?items.title:''" :label-width="name=='edits'?120:0" label-align="right"
                 :rules="items.required?[{ required: true, message: '请至少选择一项' }]:[{}]" :readonly="items.editdis">
        <template #input>
          <div class="w100">
            <div class="ftleft" @click="toChange(items)">
              <span class="font13 paddr">
                <span v-if="checkeds.length > 0" class="col3"><span v-for="(eit,ike) in checkeds" :key="ike">{{ eit }},</span></span>
                <span v-else class="colc8">请至少选择一个</span>
              </span>
            </div>
            <div class="ftright" @click="toChange(items)" v-if="items.editdis != true"><van-icon name="arrow" class=""/></div>
            <div class="ftright "><van-icon class="mr10" name="close" v-if="checkeds.length > 0 && items.editdis != true" @click="clearCheck(items)"/></div>
            <div class="clear"></div>
          </div>
<!--          <van-checkbox-group v-model="ruleForm[items.name]" direction="horizontal">
            <van-checkbox v-for="(ite,kee) in items.source.data" :key="kee" :name="ite.value" class="mt1" shape="square">
              {{ ite.label }}
            </van-checkbox>
          </van-checkbox-group>-->
        </template>
      </van-field>
    </div>
    <div v-else>
      <div v-if="isEmptyData(ruleForm[items.name])">
        <van-field disabled></van-field>
      </div>
      <div v-else>
        <van-field :rules="items.required?[{ required: true, message: '必填' }]:[{}]" readonly
                   :label="name=='edits'?items.title:''" :label-width="name=='edits'?120:0" label-align="right">
          <template #input>
            <span v-for="(iss,ke) in ruleForm[items.name]" :key="ke">{{ iss.label }}，</span>
          </template>
        </van-field>
      </div>
    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '100%' }">
      <van-sticky>
        <van-nav-bar :title="items.title"  left-arrow @click-left="getVals" left-text="确认">
          <template #right>
            <div ref="checkref" class="" @click="checkAll">
              <van-checkbox v-model="check" shape="square" icon-size="16px"><span class="lineh15">{{ check==true ?'取消全选':'全选' }}</span></van-checkbox>
            </div>
          </template>
        </van-nav-bar>
      </van-sticky>
      <div class="paddzy10">
        <van-checkbox-group v-model="ruleForm[items.name]" @change="getVal" ref="checkboxes">
          <van-cell-group class="">
            <van-cell v-for="(item,key) in diclist" clickable :key="key" :title="item.label">
              <template #right-icon>
                <van-checkbox :name="item.value" shape="square" icon-size="16px"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

    </van-popup>
    <!--              <div v-if="items.source.data.length > 0">
                <van-field :name="items.name" v-if="items.required == true" :rules="[{ required: true, message: '请至少选择一项' }]" :disabled="items.editdis">
                  <template #input>
                    <van-checkbox-group v-model="ruleForm[items.name]" direction="horizontal">
                      <van-checkbox v-for="(ite,kee) in items.source.data" :key="kee" :name="ite.value" class="mt1" shape="square">
                        {{ ite.label }}
                      </van-checkbox>
                    </van-checkbox-group>
                  </template>
                </van-field>
                <van-field :name="items.name" v-else :disabled="items.editdis">
                  <template #input>
                    <van-checkbox-group v-model="ruleForm[items.name]" direction="horizontal">
                      <van-checkbox v-for="(ite,kee) in items.source.data" :key="kee" :name="ite.value" class="mt5" shape="square">
                        {{ ite.label }}
                      </van-checkbox>
                    </van-checkbox-group>
                  </template>
                </van-field>
              </div>
              <div v-else>
                <div v-if=" isEmptyData(ruleForm[items.name])">
                  <van-field v-if="items.required == true" v-model="ruleForm[items.name]" :rules="[{ required: true, message: '必填' }]" disabled>
                    <template #input>
                      <span v-for="iss in ruleForm[items.name]" :key="iss.value">{{ iss }}，</span>
                    </template>
                  </van-field>
                  <van-field v-else v-model="ruleForm[items.name]" disabled>
                    <span v-for="iss in ruleForm[items.name]" :key="iss.value">{{ iss }}，</span>
                  </van-field>
                </div>
                <div v-else>
                  <van-field v-if="items.required == true" v-model="ruleForm[items.name]" :rules="[{ required: true, message: '必填' }]" disabled>
                    <template #input>
                      <span v-for="iss in ruleForm[items.name]" :key="iss.value">{{ iss.label }}，</span>
                    </template>
                  </van-field>
                  <van-field v-else v-model="ruleForm[items.name].label" disabled>
                    <span v-for="iss in ruleForm[items.name]" :key="iss.value">{{ iss.label }}，</span>
                  </van-field>
                </div>
              </div>-->
  </div>
</template>

<script>
import mixins from '@/mixins';
import {Toast} from "vant";
import {getDicVal} from "@/api/api";
export default {
  name: "mutiselectElement",
  props: ['items','ruleForm',"name"],
  mixins: [mixins],
  data(){
    return{
      show: false,
      diclist: [],
      checkeds: [],
      check: false,
      checkref: null,
      vals: [],
    }
  },
  created() {
  },
  mounted() {
    this.checkref = this.$refs.checkref;
    if (this.name == "edits"&&this.items.source.data.length > 0){
      if (!this.isEmptyData(this.ruleForm[this.items.name])&&this.ruleForm[this.items.name].length > 0){
        for (let i in this.ruleForm[this.items.name]){
          this.ruleForm[this.items.name][i] = this.ruleForm[this.items.name][i].value
        }
      }
    }
    this.checkeds = [];
    this.diclist = [];
    if (this.items.source.type == 'dic' && this.items.source.from != ''){
      this.getdic(this.items.source.from);
    }
    if (this.items.source.data.length > 0){
      this.diclist = this.items.source.data;
      this.getCheckval();
    }
    /*if (!this.isEmptyData(this.ruleForm[this.items.name]) && this.ruleForm[this.items.name].length > 0){
      this.checkeds = [];
      for (let i in this.ruleForm[this.items.name]){
        this.checkeds.push(this.ruleForm[this.items.name][i].label);
      }
    }*/
  },
  methods: {
    getCheckval(){
      for (let i in this.diclist){
        let it = this.diclist[i];
        if (!this.isEmptyData(this.ruleForm[this.items.name]) && this.ruleForm[this.items.name].length > 0){
          for (let i in this.ruleForm[this.items.name]){
            let its = this.ruleForm[this.items.name][i];
            if (it.value == its){
              this.checkeds.push(it.label);
            }
          }
        }
      }
    },
    //全选
    checkAll(){
      if (this.check == false){
        this.$refs.checkboxes.toggleAll(true);
      }else {
        this.$refs.checkboxes.toggleAll(false);
      }

    },
    toChange(items){
      //console.log("items",items);
      if (items.editdis){
        Toast({
          message: '无法编辑',
          position: 'top',
        });
      }else {
        this.show = true;
      }
      /*if (!this.isEmptyData(this.ruleForm[this.items.name]) && this.ruleForm[this.items.name].length > 0){
        let val = [];
        for (let i in this.ruleForm[this.items.name]){
          val.push(this.ruleForm[this.items.name][i].value);
        }
      }*/
    },
    getdic(val){
      this.diclist = [];
      let data = {
        dicType: val,
      }
      getDicVal(data).then(res => {
        //console.log("dic",res);
        if (res != null && res.data != null && res.data.content != null){
          this.diclist = res.data.content;
          this.getCheckval();
        }
      })
    },
    getVal(name){
      this.checkeds = [];
      this.vals = [];
      for (let i in this.diclist){
        let it = this.diclist[i];
        for (let j in name){
          if (it.value == name[j]){
            this.checkeds.push(it.label);
            this.vals.push(it);
          }
        }
      }
      console.log("chaeckeds111",this.checkeds);
      if (name.length == this.diclist.length){
        this.check = true;
      }else {
        this.check = false;
      }
      //this.ruleForm[this.items.name] = vals;
      /*this.$emit("getForms",name,this.items.name);*/
    },
    clearCheck(items){
      this.check = false;
      this.checkeds = [];
      this.ruleForm[items.name] = [];
    },
    getVals(){
      this.show = false;
      /*this.checkeds = this.vals.filter(i => i.label);
      console.log("chaeckeds22222",this.checkeds);
      this.ruleForm[this.items.name] = this.vals;*/
    },
  }
}
</script>

<style scoped>
.paddr{padding-right: 30vw; }
/deep/ .van-cell{padding: 12px 10px;}
</style>