<template>
  <div>
    <van-field v-if="name == 'edit'" v-model="ruleForm[items.name]" @click="opentime(items.name,)" :label="items.title"
               label-width="120" label-align="right" placeholder="请选择日期" :is-link="true"
               :rules="items.required?[{ required: true, message: '必填' }]:[{}]" :disabled="items.editdis"></van-field>
    <van-field v-else v-model="ruleForm[items.name]" @click="opentime(items.name,)" :is-link="true"
               placeholder="请选择日期" :rules="items.required?[{ required: true, message: '必填' }]:[{}]" :disabled="items.editdis"></van-field>
<!--    <div v-if="items.type=='date'">
      <van-field v-if="items.required == true" v-model="ruleForm[items.name]" @click="opentime(items.name,1)"
                 placeholder="请选择日期" :rules="[{ required: true, message: '必填' }]"></van-field>
      <van-field v-else v-model="ruleForm[items.name]" @click="opentime(items.name,1)" placeholder="请选择日期"></van-field>
    </div>-->
    <van-popup v-model="show" position="bottom" :style="{ height: '50%' }" >
      <van-datetime-picker v-model="currentDate" type="date"  title="选择年月日"  @confirm="getDate" @cancel="cancel"/>
    </van-popup>
  </div>
</template>

<script>
import mixins from "@/mixins/index"
export default {
  name: "dateElement",
  mixins: [mixins],
  props: ['items','ruleForm',"name"],
  data(){
    return{
      show: false,
      currentDate: "",
      timeitem:"",//
    }
  },
  methods: {
    getDate(value){
      this.ruleForm[this.timeitem]=this.formatDate(value);
      this.show=false;
    },
    cancel(){
      this.show=false;
    },
    opentime(item){
      this.timeitem=item;
      this.currentDate = new Date();
      this.show = true;
    },
  }
}
</script>

<style scoped>

</style>