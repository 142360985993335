<template>
  <div>
    <van-field v-if="name == 'edit'" v-model="ruleForm[items.name]" @click="opentime(items.name)" :label="items.title" label-width="120" label-align="right"
               placeholder="请选择时间" :rules="items.required?[{ required: true, message: '必填' }]:[{}]" :disabled="items.editdis"></van-field>
    <van-field v-else v-model="ruleForm[items.name]" @click="opentime(items.name)"
               placeholder="请选择时间" :rules="items.required?[{ required: true, message: '必填' }]:[{}]" :disabled="items.editdis"></van-field>
    <van-popup v-model="show" position="bottom" :style="{ height: '50%' }" >
      <van-datetime-picker v-model="currentTime" type="time"  title="选择时间"  @confirm="getTime" @cancel="show = false"/>
    </van-popup>
  </div>
</template>

<script>
import mixins from "@/mixins"
export default {
  name: "timeElement",
  mixins: [mixins],
  props: ['items','ruleForm',"name"],
  data(){
    return{
      show: false,
      currentTime: "",
      timeitem: "",
    }
  },
  methods:{
    getTime(times){
      this.ruleForm[this.timeitem] = times;
      this.show = false;
    },
    cancel(){
      this.show=false;
    },
    opentime(item){
      this.timeitem=item;
      this.currentTime = new Date();
      this.show = true;
    },
  }
}
</script>

<style scoped>

</style>