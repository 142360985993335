<template>
  <div>
    <div v-if="items.type == 'img'||items.type == 'file'">
      <van-cell v-if="!isEmptyData(ruleForm[items.name])">
        <template #extra>
          <van-image width="3rem" height="4rem" v-for="(it,k) in ruleForm[items.name].list" :key="k"
                     :src="it" class="mr5" @click="showimg(ruleForm[items.name].list)">
            <template v-slot:loading>
              <van-loading type="spinner" size="16"/>
            </template>
          </van-image>
        </template>
      </van-cell>
      <van-cell v-else></van-cell>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins"
import {ImagePreview} from "vant";

export default {
  name: "imgFile",
  props: ['items','ruleForm'],
  mixins: [mixins],
  data(){
    return{}
  },
  methods: {
    showimg(img) {//图片预览
      ImagePreview({
        images: img,
        closeable: true,
      });
    },
  }
}
</script>

<style scoped>

</style>