<template>
  <div>
    <!-- 单选 -->
    <div v-if="items.source.data.length > 0 || (items.source.from != '' && items.source.type == 'dic')">
      <!-- 判断是否必填 -->
      <van-field v-model="checked" :name="items.name" :label="name == 'edits'?items.title:''" :label-width="name == 'edits'?120:0" label-align="right"
                 :rules="items.required?[{ required: true, message: '请选择一项' }]:[{}]">
        <template #input>
          <div class="w100">
            <div class="ftleft"><span @click="toChange(items)" :class="['font13','paddr',checked != ''?'col6':'colc8'] ">{{ checked != '' ? checked : '请选择' }}</span></div>
            <div class="ftright" v-if="items.editdis != true"><van-icon @click="toChange(items)" name="arrow" class=""/></div>
            <div class="ftright"><van-icon class="mr10" name="close" v-if="checked != ''&&items.editdis != true" @click="clearCheck(items)"/></div>
            <div class="clear"></div>
          </div>
<!--          <van-radio-group v-model="ruleForm[items.name]" direction="horizontal" :disabled="items.editdis">
            <van-radio v-for="(i,k) in items.source.data" :key="k" :name="i.value" class="mt1">{{i.label}}</van-radio>
          </van-radio-group>-->
        </template>
      </van-field>
    </div>
    <div v-else>
      <div v-if="isEmptyData(ruleForm[items.name])">
        <van-field :rules="items.required?[{ required: true, message: '必填' }]:[{}]" readonly
                   :label="name == 'edits'?items.title:''" :label-width="name == 'edits'?120:0" label-align="right"></van-field>
      </div>
      <div v-else>
        <div v-if="isEmptyData(ruleForm[items.name].label)">
          <van-field v-model="ruleForm[items.name]" :label="name == 'edits'?items.title:''" :label-width="name == 'edits'?120:0" label-align="right"
                     :rules="items.required?[{ required: true, message: '必填' }]:[{}]" readonly></van-field>
        </div>
        <div v-else>
          <van-field v-model="ruleForm[items.name].label" :label="name == 'edits'?items.title:''" :label-width="name == 'edits'?120:0" label-align="right"
                     :rules="items.required?[{ required: true, message: '必填' }]:[{}]" readonly></van-field>
        </div>
      </div>

    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '100%' }">
<!--      <div class="p15 font14" style="position: fixed;">
        <div class="mright" @click="show = false">确认</div>
      </div>-->
      <van-sticky>
        <van-nav-bar :title="items.title"  left-arrow @click-left="show = false">
<!--          <template #left>
            <van-icon name="search" size="18" />
          </template>-->
        </van-nav-bar>
      </van-sticky>

      <div class="paddzy10">
        <van-radio-group v-model="ruleForm[items.name]" @change="getVal" ref="checkbox">
          <van-cell-group>
            <van-cell v-for="(i,k) in diclist" :key="k" :title="i.label" clickable>
              <template #right-icon>
                <van-radio :name="i.value" icon-size="20px"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>

    </van-popup>
    <!--              <div v-if="items.source.data.length!=0">
                &lt;!&ndash; 判断是否必填 &ndash;&gt;
                <van-field :name="items.name" v-if="items.required == true " :rules="[{ required: true, message: '请选择一项' }]">
                  <template #input>
                    <van-radio-group v-model="ruleForm[items.name]" direction="horizontal" :disabled="items.editdis">
                      <van-radio v-for="(i,k) in items.source.data" :key="k" :name="i.value" class="mt1">{{i.label}}</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
                <van-field :name="items.name" v-else>
                  <template #input>
                    <van-radio-group v-model="ruleForm[items.name]" :disabled="items.editdis" v-if="items.source.data.length!=0" direction="horizontal">
                      <van-radio v-for="(i,k) in items.source.data" :key="k" :name="i.value" class="mt1">{{i.label}}</van-radio>
                    </van-radio-group>
                    <div v-else>暂无数据</div>
                  </template>
                </van-field>
              </div>
              <div v-else>
                <div v-if="isEmptyData(ruleForm[items.name])">
                  <van-field v-if="items.required == true" :rules="[{ required: true, message: '必填' }]" disabled></van-field>
                  <van-field v-else  disabled></van-field>
                </div>
                <div v-else>
                  <div v-if="isEmptyData(ruleForm[items.name].label)">
                    <van-field v-if="items.required == true" v-model="ruleForm[items.name]" :rules="[{ required: true, message: '必填' }]" disabled></van-field>
                    <van-field v-else v-model="ruleForm[items.name]" disabled></van-field>
                  </div>
                  <div v-else>
                    <van-field v-if="items.required == true" v-model="ruleForm[items.name].label" :rules="[{ required: true, message: '必填' }]" disabled></van-field>
                    <van-field v-else v-model="ruleForm[items.name].label" disabled></van-field>
                  </div>
                </div>
              </div>-->
  </div>
</template>

<script>
import mixins from '@/mixins';
import {getDicVal} from "@/api/api";
import {Toast} from "vant";
export default {
  name: "selectElement",
  props: ['items','ruleForm','name'],
  mixins: [mixins],
  data(){
    return{
      diclist: [],
      checked: "",
      show: false,
    }
  },
  created() {
    //console.log("items1234",this.ruleForm);
    if (this.name == "edits"&&this.items.source.data.length > 0){
      this.ruleForm[this.items.name] = this.ruleForm[this.items.name].value
    }
    this.diclist = [];
    if (this.items.source.data.length > 0){
      this.diclist = this.items.source.data;
      this.getCheckval()
    }else {
      if (this.items.source.type == 'dic' && this.items.source.from != ''){
        this.getdic(this.items.source.from);
      }
    }
  },
  methods: {
    getCheckval(){
      this.checked = "";
      for (let i in this.diclist){
        let it = this.diclist[i];
        if (!this.isEmptyData(this.ruleForm[this.items.name])){
          let its = this.ruleForm[this.items.name];
          if (!this.isEmptyData(its.value)){
            if (it.value == its.value){
              this.checked = it.label;

            }
          }else {
            if (it.value == its){
              this.checked = it.label;
              //console.log("checked",this.checked)
            }
          }
        }
      }
    },
    toChange(items){
      //console.log("items",items);
      if (items.editdis){
        Toast({
          message: '无法编辑',
          position: 'top',
        });
      }else {
        this.show = true;

        if (!this.isEmptyData(this.items.source.conditions) && this.items.source.conditions.length > 0 && this.items.source.type == 'dic' && this.items.source.from != ''){
          this.getdic(this.items.source.from);
        }
      }
    },
    setPdic(){
      let data = "";
      if (!this.isEmptyData(this.items.source.conditions) && this.items.source.conditions.length > 0){
        let condition = this.items.source.conditions[0];
        if (!this.isEmptyData(this.ruleForm[condition.field])&& JSON.stringify(this.ruleForm[condition.field]!='{}')){
          data = this.ruleForm[condition.field];
        }
      }
      return data;
    },
    getdic(val){
      this.diclist = [];
      let pdicCode = "";
      //console.log("1111")
      if (!this.isEmptyData(this.items)){
        pdicCode = this.setPdic();
      }
      //判断是否有配置条件，如果有配置条件
      if (!this.isEmptyData(this.items.source) && !this.isEmptyData(this.items.source.conditions) && this.items.source.conditions.length > 0){
        if (this.isEmptyData(pdicCode)){
          if (!this.isEmptyData(this.ruleForm[this.items.name])){
            this.$set(this.ruleForm,this.items.name, "");
            this.checked = "";
            this.diclist = [];
          }
          return;
        }
      }
      let data = {
        dicType: val,
        pdicCode: pdicCode,
      }
      getDicVal(data).then(res => {
        //console.log("dic11---",res);
        if (res != null && res.data != null && res.data.content != null){
          this.diclist = res.data.content;
          this.getCheckval();
        }
      })
    },
    getVal(name){
      //console.log("name",name);
      for (let i in this.diclist){
        if (this.diclist[i].value == name){
          this.checked = this.diclist[i].label;
          //this.ruleForm[this.items.name] = this.diclist[i];
          this.show = false;
        }
      }
      /*this.$emit("getForms",this.ruleForm[this.items.name],this.items.name);*/
    },
    clearCheck(items){
      this.checked = '';
      this.ruleForm[items.name] = '';
    },
  }
}
</script>

<style scoped>
.paddr{padding-right: 50vw;}
/deep/ .van-cell{padding: 12px 10px;}
</style>